export enum SortDirection {
  Ascending,
  Descending,
  None,
}

export default class PagedQueryRequest {
  Skip = 0;
  Take = 10;
  Sort: SortDirection = SortDirection.None;
  Search = "";
  SortColumn = "";

  //   updateFromTableOptions(options: DataOptions) {
  //     const page: number = options.page;
  //     this.Take = options.itemsPerPage;
  //     this.Skip = this.Take * (page - 1);

  //     if (options.sortBy.length > 0) {
  //       this.SortColumn = options.sortBy[0];
  //       const desc = options.sortDesc[0];
  //       this.Sort = desc ? SortDirection.Descending : SortDirection.Ascending;
  //     } else {
  //       this.SortColumn = "";
  //       this.Sort = SortDirection.None;
  //     }
  //   }

  //   loadFromJson(json: object) {
  //     const q = json as any;
  //     this.Search = q.Search;
  //     this.Skip = q.Skip;
  //     this.Sort = q.Sort;
  //     this.SortColumn = q.SortColumn;
  //     this.Take = q.Take;
  //   }
}
