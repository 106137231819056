import axios from "axios";
import UseCaseResponse from "@/types/UseCaseResponse";
import BaseApiService from "./BaseApiService";
import RouteData from "@/types/pocos/RouteData";

export default class RouteDataApiService extends BaseApiService {
  async GetRouteData(
    formName: string,
    instanceName: string | null
  ): Promise<UseCaseResponse<RouteData>> {
    await this.defineHeader();

    let u = `${this.url}/api/registration/RouteData?formName=${formName}`;

    if (instanceName) {
      u += `&instanceName=${instanceName}`;
    }

    return axios.get(u).then((resp) => resp.data as UseCaseResponse<RouteData>);
  }
}
