<template>
  <b-container fluid="xl">
    <div style="max-width: 100%; margin: 0 auto; margin-top: 40px">
      <b-img
        fluid
        src="@/assets/surveys/school_organization/LTS_Survey_Header.png"
        alt="School / Organization Survey Header"
        class="headerimg"
      />
    </div>

    <h1 class="h2">{{ $t("SchoolOrganizationSurvey.Header") }}</h1>

    <div v-if="!submissionComplete">
      <p>
        {{ $t("SchoolOrganizationSurvey.Intro") }}
      </p>
      <hr />

      <validation-observer v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <validation-provider
            v-slot="{ errors, valid }"
            name="country"
            :rules="{ required: true }"
          >
            <b-form-group
              id="country-group"
              label="Country"
              label-for="country"
              class="required-field mb-4"
            >
              <b-form-select
                id="country"
                class="binput"
                v-model="model.Country"
                :options="countryOptions"
                :state="errors[0] ? false : valid ? true : null"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="valid">
              <span v-for="(error, index) in errors" :key="index">{{
                $t(error)
              }}</span>
            </b-form-invalid-feedback>
          </validation-provider>

          <your-school
            v-if="model.Country === 'Canada'"
            v-model="model.School"
          />

          <b-button
            id="btn_submit"
            type="submit"
            class="mt-4"
            variant="primary"
            :disabled="saving"
            >{{ $t("Submit") }}</b-button
          >
        </b-form>
      </validation-observer>
    </div>

    <!-- Confirmation -->
    <div v-else>
      <hr />
      <p id="confirmationbody">
        {{ $t("SchoolOrganizationSurvey.Confirmation") }}
      </p>
    </div>
  </b-container>
</template>

<script lang="ts">
import { Component } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import OrganizationApiService from "@/services/OrganizationApiService";
import YourSchool from "@/components/questions/YourSchool.vue";
import { Vue } from "vue-property-decorator";
import SurveyApiService from "@/services/SurveyApiService";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    YourSchool,
  },
})
export default class SchoolOrganization extends Vue {
  model: any = {
    School: {},
  };

  apiService: SurveyApiService = new SurveyApiService();

  get SurveyName(): string {
    return "SchoolOrganization";
  }

  get SurveyVersion(): number {
    return 2;
  }

  get countryOptions() {
    return [
      { text: "Canada", value: "Canada" },
      { text: this.$root.$t("Canvas.Countries.Other"), value: "Other" },
    ];
  }

  orgApiService: OrganizationApiService = new OrganizationApiService();
  submissionComplete = false;
  saving = false;

  onSubmit() {
    this.apiService
      .CreateResponse(this.SurveyName, this.SurveyVersion, this.model)
      .then((resp) => {
        if (resp.Success) {
          this.submissionComplete = true;
        } else {
          console.log(resp);
        }
      });
  }
}
</script>
