<template>
  <b-container fluid="xl" v-if="elevated">
    <b-input-group
      id="forms-group"
      prepend="Form"
      class="mt-3"
      label-for="forms"
    >
      <b-form-select
        id="forms"
        class="binput"
        :options="formOptions"
        v-model="query.FormId"
        @input="refreshTable"
      />
      <b-input-group-append>
        <b-button @click="onClearFormClicked">Clear</b-button>
      </b-input-group-append>
    </b-input-group>
    <b-input-group
      id="instances-group"
      prepend="Instance"
      class="mt-3"
      label-for="instances"
    >
      <b-form-select
        id="instances"
        class="binput"
        :options="Instances"
        v-model="query.InstanceId"
        @input="refreshTable"
      />
      <b-input-group-append>
        <b-button @click="query.InstanceId = ''">Clear</b-button>
      </b-input-group-append>
    </b-input-group>
    <b-input-group
      id="email-group"
      prepend="Email"
      class="mt-3"
      label-for="email"
    >
      <b-input
        id="email"
        class="binput"
        v-model="query.Search"
        @input="onEmailInput"
      />
    </b-input-group>
    <div>
      <b-table
        id="submissions-table"
        ref="subtbl"
        responsive
        striped
        hover
        :fields="fields"
        :items="querySubmissions"
        :per-page="items_per_page"
        :current-page="current_page"
      >
        <template #cell(FormId)="data">
          {{ getFormNameById(data.item.FormId) }}
        </template>
        <template #cell(FormInstanceId)="data">
          {{ getInstanceNameById(data.item.FormInstanceId) }}
        </template>
        <template #cell(CreatedOn)="data">
          {{ new Date(data.item.CreatedOn).toLocaleString() }}
        </template>
        <template #cell(Actions)="data">
          <b-dropdown text="Actions">
            <b-dropdown-item
              @click="onViewClicked(data.item.RegistrationJson)"
              v-b-modal.modal-reg
              >View</b-dropdown-item
            >
            <b-dropdown-item @click="onEditClicked(data.item)"
              >Edit</b-dropdown-item
            >
          </b-dropdown>
        </template>
      </b-table>
      <b-pagination
        v-model="current_page"
        :total-rows="total_rows"
        :per-page="items_per_page"
        align="right"
        aria-controls="submissions-table"
      />
    </div>
    <b-modal
      id="modal-reg"
      title="Registration Json"
      scrollable
      centered
      size="xl"
    >
      <pre class="my-4">
        {{ registration_string }}
      </pre>
    </b-modal>
  </b-container>
  <div v-else>404</div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import FormApiService from "@/services/FormApiService";
import AuthorizationService from "@/services/AuthorizationService";
import SubmissionApiService from "@/services/SubmissionApiService";
import QuerySubmissionsRequest from "@/types/pocos/QuerySubmissionsRequest";
import Form from "@/types/Entities/Form";
import FormInstance from "@/types/Entities/FormInstance";
import Submission from "@/types/Entities/Submission";

@Component
export default class SubmissionList extends Vue {
  name = "";
  model: any = {};
  forms: Array<Form> = [];
  form_instances: Array<FormInstance> = [];
  registration_string = "";

  current_page = 1;
  total_rows = 0;
  items_per_page = 10;
  query: QuerySubmissionsRequest = new QuerySubmissionsRequest();

  typeTimer: number = 1000 * 0.25; //1000 * seconds = milliseconds
  timeout: any | null;

  authService: AuthorizationService = AuthorizationService.getInstance();
  formApiService: FormApiService = new FormApiService();
  submissionApiService: SubmissionApiService = new SubmissionApiService();

  fields: object[] = [
    {
      key: "FormId",
      label: "Form",
    },
    {
      key: "FormInstanceId",
      label: "Instance",
    },
    {
      key: "FormVersion",
      label: "Form Version",
    },
    {
      key: "UserEmail",
      label: "User",
    },
    {
      key: "CreatedOn",
      label: "Created",
    },
    {
      key: "Actions",
      label: "",
    },
  ];

  elevated = AuthorizationService.getInstance().hasRole([
    "staff",
    "administrator",
  ]);

  get formOptions() {
    return this.forms.map((f) => {
      return {
        text: f.Name,
        value: f.Id,
      };
    });
  }

  get Instances(): object[] {
    const form = this.forms.find((f) => f.Id === this.query.FormId);
    if (!form || !form.FormInstances || form.FormInstances.length === 0) {
      return [];
    } else {
      return form.FormInstances.map((fi) => {
        return {
          text: fi.Name,
          value: fi.Id,
        };
      });
    }
  }

  mounted() {
    this.formApiService.GetForms().then((resp) => {
      if (resp.Success) {
        this.forms = resp.Value as Array<Form>;
        if (this.forms.length > 0) {
          this.form_instances = this.forms.flatMap(
            (f) => f.FormInstances
          ) as FormInstance[];
        }
      }
    });
  }

  getFormNameById(form_id: string) {
    const f = this.forms.find((f) => f.Id === form_id);
    if (f) {
      return f.Name;
    } else {
      return "";
    }
  }

  getInstanceNameById(instance_id: string | null) {
    if (!instance_id) {
      return "";
    }

    const i = this.form_instances.find((fi) => fi.Id === instance_id);
    if (i) {
      return i.Name;
    } else {
      return "";
    }
  }

  onEmailInput() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.refreshTable();
    }, this.typeTimer);
  }

  onClearFormClicked() {
    this.query.FormId = "";
    this.query.InstanceId = "";
  }

  onEditClicked(reg: Submission) {
    const form = this.forms.find((f) => f.Id === reg.FormId);
    if (!form) {
      return;
    }

    const instance = form.FormInstances?.find(
      (i) => i.Id === reg.FormInstanceId
    );

    console.log(form);
    console.log(instance);

    let url = `${form.Name}`;
    if (instance) {
      url += `/${instance.Name}`;
    }

    url += `?id=${reg.Id}`;
    console.log(url);

    this.$router.push(url);
  }

  refreshTable() {
    if (this.$refs.subtbl) {
      (this.$refs.subtbl as any).refresh();
    }
  }

  querySubmissions(ctx: any, callback: any) {
    this.query.Skip = (this.current_page - 1) * this.items_per_page;
    this.query.Take = this.items_per_page;

    this.submissionApiService
      .QuerySubmissions(this.query)
      .then((resp) => {
        if (resp.Success) {
          this.total_rows = resp.TotalRecords;
          callback(resp.Value);
        }
      })
      .catch(() => callback([]));

    return null;
  }

  onViewClicked(reg: string) {
    reg = JSON.parse(reg);
    this.registration_string = JSON.stringify(reg, null, 2);
  }
}
</script>
